import React from 'react';

const SimpleButton = ({
  onClick,
  text,
  extraCSS = {},
}: {
  onClick: (event: any) => void;
  text: string;
  extraCSS?: any;
}) => {
  return (
    <div
      onClick={onClick}
      css={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifContent: 'center',
        cursor: 'pointer',
        color: '#0000EE',
        textDecoration: 'underline',
        // marginRight: 12,
        ...extraCSS,
      }}
    >
      {text}
    </div>
  );
};

export default SimpleButton;
