import React, { useEffect } from 'react';
// import Dimensions from 'utils/Dimensions';
// import { isMobile } from 'react-device-detect';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';
import Box from 'components/Box';
import LoginOrLogoutButton from 'components/LoginOrLogoutButton';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import { useNavigate, useLocation } from 'react-router-dom';
import { BoxProvider } from 'contexts/boxContext';
import AuthDS from 'data/AuthDS';
import ModalsDS from 'data/ModalsDS';
import SimpleButton from 'components/SimpleButton';
import HowDoesItWorkModal from 'components/HowDoesItWorkModal';

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [isHowDoesItWorkModalOpen, setIsHowDoesItWorkModalOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('uploadPiece')) {
      localStorage.setItem('uploadPiece', 'true');
      AuthDS.get().openAuthModal();
    } else if (searchParams.has('error')) {
      alert('Error: must use a valid RCA email address');

      // After the alert is closed, remove the 'error' parameter from the URL
      searchParams.delete('error');

      // Update the URL without reloading the page
      window.history.replaceState({}, '', `${location.pathname}?${searchParams}`);
    }
  }, [location]);

  const handleLogoClick = useUpdatingCallback(() => {
    navigate('/');
  });

  const handleClickHowDoesItWork = useUpdatingCallback(() => {
    ModalsDS.get().openHowItWorksModal();
  });

  const backgroundBoxes = [
    <Box width={200} height={50}>
      <div onClick={handleLogoClick} css={{ position: 'relative', cursor: 'pointer' }}>
        <img src={process.env.PUBLIC_URL + '/tngbl_logo.svg'} css={{ width: 145 }} alt='logo' />
      </div>
    </Box>,
    <Box width={170} height={75}>
      <LoginOrLogoutButton isInLandingPage />
    </Box>,
    // <Box width={150} height={75}>
    //   <a
    //     target='_blank'
    //     rel='noopener noreferrer'
    //     href='https://airtable.com/appvIEs3OYTLGWrmX/shrd2HKJ8GYsquoyl'
    //   >
    //     Join waitlist
    //   </a>
    // </Box>,
    <Box width={400} height={400} canHover={false} extraCSS={{ padding: 0 }}>
      <img
        alt='MailArt'
        src={process.env.PUBLIC_URL + '/cohen_1986.jpg'}
        css={{
          width: '100%',

          objectFit: 'cover',
          left: 0,
        }}
      />
    </Box>,
    <Box
      width={390}
      height={400}
      canHover={false}
      extraCSS={{
        padding: 0,
      }}
    >
      <img
        alt='Face'
        src={process.env.PUBLIC_URL + '/face.jpeg'}
        css={{
          width: 410,
          objectFit: 'cover',
          left: 0,
        }}
      />
    </Box>,
    <Box width={300} height={480}>
      <div css={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 16 }}>
        <p>
          Hi there,
          <br />
          <br />
          We are <strong>TNGBL [tangible]</strong>, a student-run <strong>art platform</strong> at
          the <strong>RCA</strong> dedicated to creating a space for discovering new work by your
          peers.
          <br />
          <br />
          We take inspiration from the 1960s <strong>MailArt</strong> movement, which encourages
          sending small-scale works through the postal service. It promotes an egalitarian way of
          exchanging art, outside of the traditional market.
          <br />
          <br />
          Track your collection, see what your friends are <strong>making/collecting</strong>,
          discover new work.
        </p>
      </div>
    </Box>,
    <Box width={200} height={75}>
      <SimpleButton onClick={handleClickHowDoesItWork} text='How does it work?' />
    </Box>,
  ];

  return (
    <div
      css={{
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // maxWidth: isMobile ? '100vw' : Dimensions.MAX_BODY_WIDTH,
        // minWidth: isMobile ? '100vw' : Dimensions.MIN_BODY_WIDTH,
        width: '100vw',
        height: '100vh',
        flex: 1,
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      <HowDoesItWorkModal />
      <BoxProvider
        backgroundBoxes={backgroundBoxes}
        foregroundBoxes={[]}
        boundingBoxSize={{ width: window.innerWidth, height: window.innerHeight }}
        positioningConfig={{
          angle: 0,
          gap: 200,
          angleIncrement: 0.6,
          jitterMax: 60,
          spread: 0.25,
        }}
      />
      <div
        css={{
          position: 'absolute',
          bottom: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        <a href='mailto:team@tngbl.art'>team@tngbl.art</a>
        <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/tngbl.art'>
          instagram
        </a>
        <a target='_blank' rel='noopener noreferrer' href='/privacy'>
          privacy policy
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
