import React, { createContext, useContext, useState, useLayoutEffect, ReactElement } from 'react';

interface BoxProps {
  width: number;
  height: number;
  index?: number;
}

interface BoxPosition {
  initialLeft: number;
  left: number;
  top: number;
  boxWidth: number;
  zIdx: number;
}

interface HoverIndex {
  index: number | null;
  type: 'background' | 'foreground' | null;
}

interface BoxContextType {
  backgroundBoxPositions: BoxPosition[];
  foregroundBoxPositions?: BoxPosition[];
  setHoverIndex: React.Dispatch<React.SetStateAction<HoverIndex>>;
  hoverIndex: HoverIndex;
}

const BoxPositionContext = createContext<BoxContextType | undefined>(undefined);

export const useBoxPosition = (): BoxContextType => {
  const context = useContext(BoxPositionContext);
  if (!context) {
    throw new Error('useBoxPosition must be used within a BoxProvider');
  }
  return context;
};

interface BoxProviderProps {
  backgroundBoxes: ReactElement<BoxProps>[];
  foregroundBoxes: ReactElement<BoxProps>[];
  boundingBoxSize?: { width: number; height: number }; // e.g., { width: '50vw', height: '50vh' }
  positioningConfig?: {
    angle: number;
    gap: number;
    angleIncrement: number;
    jitterMax: number;
    spread: number;
  };
}

export const BoxProvider: React.FC<BoxProviderProps> = ({
  backgroundBoxes,
  foregroundBoxes,
  boundingBoxSize = { width: window.innerWidth / 2, height: window.innerHeight / 2 },
  positioningConfig,
}) => {
  const [backgroundBoxPositions, setBackgroundBoxPositions] = useState<BoxPosition[]>([]);
  const [foregroundBoxPositions, setForegroundBoxPositions] = useState<BoxPosition[]>([]);
  const [hoverIndex, setHoverIndex] = useState<HoverIndex>({ index: null, type: null });

  useLayoutEffect(() => {
    const sortedBackgroundBoxes = backgroundBoxes.sort(
      (a, b) => a.props.width * a.props.height - b.props.width * b.props.height,
    );

    const initialBackgroundBoxPositions = calculateBackgroundBoxPositions(
      sortedBackgroundBoxes,
      positioningConfig,
    );
    setBackgroundBoxPositions(initialBackgroundBoxPositions);

    const sortedForegroundBoxes = foregroundBoxes.sort(
      (a, b) => a.props.width * a.props.height - b.props.width * b.props.height,
    );
    const initialForegroundBoxPositions = calculateForegroundBoxPositions(
      sortedForegroundBoxes,
      boundingBoxSize,
      backgroundBoxPositions.length,
    );
    setForegroundBoxPositions(initialForegroundBoxPositions);

    const timer = setTimeout(() => {
      setBackgroundBoxPositions(
        initialBackgroundBoxPositions.map((position) => ({
          ...position,
          initialLeft: position.left,
        })),
      );
      setForegroundBoxPositions(
        initialForegroundBoxPositions.map((position) => ({
          ...position,
          initialLeft: position.left,
        })),
      );
    }, 50);

    return () => clearTimeout(timer);
  }, [backgroundBoxes, foregroundBoxes]);

  return (
    <BoxPositionContext.Provider
      value={{ backgroundBoxPositions, foregroundBoxPositions, setHoverIndex, hoverIndex }}
    >
      {foregroundBoxes.map((box, index) =>
        React.cloneElement(box, { key: `boxContext_${index}`, index }),
      )}
      {backgroundBoxes.map((box, index) =>
        React.cloneElement(box, { key: `boxContext_${index}`, index }),
      )}
    </BoxPositionContext.Provider>
  );
};

const calculateBackgroundBoxPositions = (
  boxes: ReactElement<BoxProps>[],
  config: {
    angle: number;
    gap: number;
    angleIncrement: number;
    jitterMax: number;
    spread: number;
  } = {
    angle: 0,
    gap: 60,
    angleIncrement: 0.6,
    jitterMax: 60,
    spread: 0.1,
  },
): BoxPosition[] => {
  const positions: BoxPosition[] = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  let spiralAngle = config.angle || 0;
  let baseGap = config.gap || 60;
  let baseAngleIncrement = config.angleIncrement || 0.6;
  let jitterMax = config.jitterMax || 60;

  // Adjustments based on the number of boxes
  const boxCount = boxes.length;
  let radiusAdjustmentFactor = boxCount < 20 ? 1 + (20 - boxCount) * config.spread : 1;
  let gap = baseGap;
  let angleIncrement = baseAngleIncrement;
  const buffer = 4;

  const randomJitter = () => Math.random() * jitterMax * 2 - jitterMax;

  for (let i = 0; i < boxes.length; i++) {
    const box = boxes[i];
    const boxSize = Math.max(box.props.width, box.props.height);

    // Adjust the radius based on the number of boxes
    let radius = (((boxSize + gap) * i) / (2 * Math.PI)) * radiusAdjustmentFactor;
    let zIdx = boxes.length - i;

    let x = center.x + radius * Math.cos(spiralAngle) - boxSize / 2 + randomJitter();
    let y = center.y + radius * Math.sin(spiralAngle) - boxSize / 2 + randomJitter();
    let initialX = x - (Math.random() * 50 + 50) * (Math.random() < 0.5 ? 1 : -1);

    // Check if the box is outside the window boundaries
    if (
      x < buffer ||
      x + boxSize > window.innerWidth ||
      y < 4 ||
      y + boxSize > window.innerHeight
    ) {
      x = Math.max(buffer, Math.random() * (window.innerWidth - boxSize));
      y = Math.max(buffer, Math.random() * (window.innerHeight - boxSize));
    }

    positions.push({ initialLeft: initialX, left: x, top: y, boxWidth: boxSize, zIdx });
    spiralAngle += angleIncrement;
  }

  const calculateInitialPosition = (finalPosition, boxWidth) => {
    const maxOffset = 100;
    const minLeft = 0;
    const maxLeft = window.innerWidth - boxWidth;
    let initialLeft = finalPosition - (Math.random() * maxOffset * 2 - maxOffset);
    initialLeft = Math.min(Math.max(initialLeft, minLeft), maxLeft);
    return initialLeft;
  };

  const finalPositions = positions.map((position) => ({
    ...position,
    initialLeft: calculateInitialPosition(position.left, position.boxWidth),
  }));

  return finalPositions;
};

const calculateForegroundBoxPositions = (
  boxes: ReactElement<BoxProps>[],
  boundingBoxSize: { width: number; height: number },
  startIndex: number,
): BoxPosition[] => {
  const boundingBoxTopLeft = {
    x: (window.innerWidth - boundingBoxSize.width) / 2,
    y: (window.innerHeight - boundingBoxSize.height) / 2,
  };

  const positions: BoxPosition[] = [];
  boxes.forEach((box, index) => {
    const finalLeft = Math.max(
      boundingBoxTopLeft.x,
      boundingBoxTopLeft.x + Math.random() * (boundingBoxSize.width - box.props.width),
    );
    const finalTop = Math.max(
      boundingBoxTopLeft.y,
      boundingBoxTopLeft.y + Math.random() * (boundingBoxSize.height - box.props.height),
    );

    const initialLeft = finalLeft - (Math.random() * 50 + 50) * (Math.random() < 0.5 ? 1 : -1); // Random initial left for animation

    positions.push({
      initialLeft: initialLeft,
      left: finalLeft,
      top: finalTop,
      boxWidth: box.props.width,
      zIdx: startIndex + index + 100,
    });
  });

  return positions;
};

export default BoxProvider;
