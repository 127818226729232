import { useEffect, useState } from 'react';
import CallAPI from 'utils/CallAPI';
import Profile from 'types/Profile';

const useDidUserCreateProfile = () => {
  const [didUserCreateProfile, setDidUserCreateProfile] = useState<boolean>(false);
  const [existingProfile, setExistingProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    __genGetDidUserCreateProfile({ setDidUserCreateProfile, setIsLoading, setExistingProfile });
  }, []);

  const genGetDidUserCreateProfile = async () => {
    return await __genGetDidUserCreateProfile({
      setDidUserCreateProfile,
      setIsLoading,
      setExistingProfile,
    });
  };

  return {
    didUserCreateProfile,
    genGetDidUserCreateProfile,
    existingProfile,
    isLoading,
  };
};

const __genGetDidUserCreateProfile = async ({
  setDidUserCreateProfile,
  setIsLoading,
  setExistingProfile,
}) => {
  try {
    const res = await CallAPI.getDidUserCreateProfile();
    setDidUserCreateProfile(res.data.result.didCreateProfile);
    setExistingProfile(res.data.result.existingProfile);
    return res.data.result.didCreateProfile;
  } catch (err) {
    console.log(err);
  } finally {
    setIsLoading(false);
  }
};

export default useDidUserCreateProfile;
