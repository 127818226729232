import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import CallAPI from 'utils/CallAPI';
// import useProfile from 'contexts/profileContext';
import useDidUserCreateProfile from 'hooks/useDidUserCreateProfile';
import { useNavigate } from 'react-router-dom';
import useUser from 'contexts/userContext';
import ModalsDS, { useModals } from 'data/ModalsDS';
import { isMobile } from 'react-device-detect';

const CreateProfileFormSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  program: Yup.string().required('Program is required'),
  website: Yup.string(),
  instagram: Yup.string(),
  photo: Yup.mixed().required('Profile picture is required'),
  bio: Yup.string().required('Bio is required'),
  is_collector: Yup.boolean(),
  is_creator: Yup.boolean(),
});

const CreateProfileForm = () => {
  const navigate = useNavigate();
  const { userId } = useUser();
  const { didUserCreateProfile, genGetDidUserCreateProfile, existingProfile, isLoading } =
    useDidUserCreateProfile();
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState({
    username: '',
    firstName: '',
    lastName: '',
    phone: '',
    program: '',
    website: '',
    instagram: '',
    bio: '',
    photo: '',
    is_collector: false,
    is_creator: false,
  });
  const isOpen = useModals({ transform: (x) => x?.isProfileModalOpen });

  useEffect(() => {
    if (!didUserCreateProfile && !isLoading) {
      ModalsDS.get().openProfileModal();
    }
  }, [didUserCreateProfile, isLoading]);

  useEffect(() => {
    if (existingProfile) {
      setInitialValues({
        username: existingProfile.username || '',
        firstName: existingProfile.first_name || '',
        lastName: existingProfile.last_name || '',
        phone: existingProfile.phone || '',
        program: existingProfile.program || '',
        website: existingProfile.website || '',
        instagram: existingProfile.instagram || '',
        bio: existingProfile.bio || '',
        photo: (existingProfile.photo && existingProfile.photo[0]?.url) || '',
        is_collector: false,
        is_creator: false,
      });

      if (existingProfile.photo && !photoPreview) {
        setPhotoPreview(existingProfile.photo[0].url);
      }
    }
  }, [existingProfile]);

  const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
    const file = values.photo;
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 500 * 1024 * 1024; // 500MB

    if (file.type && !validTypes.includes(file.type)) {
      setFieldError('images', 'Invalid file type');
      setSubmitting(false);
      return;
    }
    if (file.size > maxSize) {
      setFieldError('images', 'File size exceeds limit');
      setSubmitting(false);
      return;
    }

    try {
      // Call the API method for image upload
      await CallAPI.postUpdateProfile({
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        bio: values.bio,
        phone: values.phone,
        website: values.website,
        program: values.program,
        instagram: values.instagram,
        is_collector: values.is_collector,
        is_creator: values.is_creator,
        ...(file.type && { photo: file }),
        // photo: values.photo,
      });
      const success = await genGetDidUserCreateProfile();
      if (success) {
        ModalsDS.get().closeProfileModal();

        // if was trying to upload piece, navigate to form on profile page
        const wantsToUploadPiece = localStorage.getItem('uploadPiece');
        if (wantsToUploadPiece === 'true') {
          localStorage.removeItem('uploadPiece');
          navigate(`/profile/${userId}?uploadPiece`);
        }
      }
      // Handle success (e.g., show success message, close form)
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error('Error uploading images:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (event, setFieldValue) => {
    if (!event?.currentTarget?.files) return;
    const file = event.currentTarget.files[0];
    setPhotoPreview(URL.createObjectURL(file)); // Set photo preview
    setFieldValue('photo', file);
  };

  if (!isOpen || isLoading) return null;

  return (
    <Modal open={isOpen} onClose={() => {}} css={{ outline: 'none' }}>
      <div
        css={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? 380 : 400,
          border: '1px solid black',
          background: 'white',
          padding: 24,
          outline: 'none',
          color: 'black',
        }}
      >
        <h3 css={{ marginTop: 0 }}>Create Profile</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={CreateProfileFormSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form css={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <__FormFieldRow name='username' label='Username'>
                <Field type='text' name='username' placeholder='Username' css={{ width: '100%' }} />
              </__FormFieldRow>
              <__FormFieldRow name='firstName' label='First Name'>
                <Field
                  type='text'
                  name='firstName'
                  placeholder='First name'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <__FormFieldRow name='lastName' label='Last Name'>
                <Field
                  type='text'
                  name='lastName'
                  placeholder='Last name'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <__FormFieldRow name='phone' label='Phone Number'>
                <Field
                  type='text'
                  name='phone'
                  placeholder='Phone number'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <__FormFieldRow name='program' label='Program'>
                <Field
                  type='text'
                  name='program'
                  placeholder='Your RCA program'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <__FormFieldRow name='website' label='Website'>
                <Field
                  type='text'
                  name='website'
                  placeholder='Your personal website'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <__FormFieldRow name='instagram' label='Instagram'>
                <Field
                  type='text'
                  name='instagram'
                  placeholder='Your IG handle'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>
              <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                    position: 'relative',
                    // height: 100,
                  }}
                >
                  <label htmlFor='bio' css={{ fontSize: 12, width: 150 }}>
                    {'Bio'}:
                  </label>
                  <Field
                    component='textarea'
                    name='bio'
                    placeholder='Your bio'
                    rows={4}
                    cols={41}
                    css={{ minWidth: 246, maxWidth: 246, minHeight: 100, maxHeight: 62 }}
                  />
                </div>
                <ErrorMessage name='bio' component='div' css={{ color: 'red', fontSize: 12 }} />
              </div>
              <__FormFieldRow name='is_collector' label='I want to collect work'>
                <Field type='checkbox' name='is_collector' />
              </__FormFieldRow>
              <__FormFieldRow name='is_creator' label='I want to share my work'>
                <Field type='checkbox' name='is_creator' />
              </__FormFieldRow>
              <div
                css={{
                  // display: 'flex',
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '100%',
                  height: 48,
                }}
              >
                <label htmlFor='photo' css={{ fontSize: 12, width: 150 }}>
                  {'Profile picture'}:
                </label>
                <input
                  id='file'
                  name='photo'
                  type='file'
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  accept='image/jpeg, image/png'
                  css={{ color: 'transparent', position: 'absolute', right: 0, width: 90 }}
                />

                <ErrorMessage name='photo' component='div' css={{ color: 'red', fontSize: 12 }} />
              </div>
              {photoPreview && (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    width: '100%',
                  }}
                >
                  <img src={photoPreview} alt='Preview' css={{ height: 200 }} />
                </div>
              )}
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: 8,
                }}
              >
                <button type='submit' disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const __FormFieldRow = ({ name, label, labelStyles = {}, children }) => (
  <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        position: 'relative',
      }}
    >
      <label htmlFor={name} css={{ fontSize: 12, width: 150, ...labelStyles }}>
        {label}:
      </label>
      {children}
    </div>
    <ErrorMessage name={name} component='div' css={{ color: 'red', fontSize: 12 }} />
  </div>
);

export default CreateProfileForm;
