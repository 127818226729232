import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from 'components/Box';
import UploadPieceButton from 'components/UploadPieceButton';
import { BoxProvider } from 'contexts/boxContext';
import LoginOrLogoutButton from 'components/LoginOrLogoutButton';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import useProfile from 'contexts/profileContext';
import SimpleButton from 'components/SimpleButton';
import EditProfileForm from 'components/EditProfileForm';
import useUser from 'contexts/userContext';
import ModalsDS from 'data/ModalsDS';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';
import { isMobile } from 'react-device-detect';

const ProfilePage = () => {
  const { userId } = useUser();
  const navigate = useNavigate();
  const { profile, profilePieces } = useProfile();

  const handleClickEdit = useUpdatingCallback(() => {
    ModalsDS.get().openProfileModal();
  });

  if (!profile) return null;

  let foregroundBoxes = [
    <Box width={250} height={150} isForeground extraCSS={{ padding: 8 }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%',
          position: 'relative',
          fontSize: 12,
        }}
      >
        <div
          css={{ fontSize: 18, fontWeight: 700 }}
        >{`${profile.first_name} ${profile.last_name}`}</div>
        <div css={{ fontStyle: 'italic' }}>{`${profile.program}`}</div>
        <div css={{ marginTop: 8 }}>{`${profile.bio}`}</div>
        {profile.is_collector || profile.is_creator ? (
          <div
            css={{
              position: 'absolute',
              right: 0,
              bottom: -4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {profile.is_collector ? <div css={{ fontWeight: 700 }}>collector</div> : null}
            {profile.is_collector && profile.is_creator ? (
              <div css={{ fontSize: 14, fontWeight: 700, marginRight: 4, marginLeft: 4 }}>
                &middot;
              </div>
            ) : null}
            {profile.is_creator ? <div css={{ fontWeight: 700 }}>creator</div> : null}
          </div>
        ) : null}
      </div>
    </Box>,
    <Box key='login_box' height={60} width={100} isForeground>
      <LoginOrLogoutButton isInLandingPage />
    </Box>,
  ];

  if (userId === profile.uid) {
    foregroundBoxes.push(
      <Box key='upload_piece_box' height={60} width={150} isForeground>
        <UploadPieceButton />
      </Box>,
    );
    foregroundBoxes.push(
      <Box key='profile_box' height={60} width={100} isForeground>
        <SimpleButton onClick={handleClickEdit} text='Edit profile' />
      </Box>,
    );
  }

  if (profile?.instagram) {
    foregroundBoxes.push(
      <Box index={1} width={150} height={60}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={`https://instagram.com/${profile.instagram.replace('@', '')}`}
        >
          {`@${profile.instagram.replace('@', '')}`}
        </a>
      </Box>,
    );
  }

  const backgroundBoxes = profilePieces.map((piece) => (
    <Box key={piece.uid} width={200} height={200}>
      <img
        src={piece?.images[0]?.url}
        alt={piece?.title}
        css={{ width: 200, cursor: 'pointer', filter: 'none' }} // piece?.is_verified ? 'none' : 'blur(8px)'
        onClick={() => navigate(`/piece/${piece.uid}`)}
      />
    </Box>
  ));

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // height: `calc(100vh - ${Dimensions.HEADER_HEIGHT}px)`,
        // top: Dimensions.HEADER_HEIGHT,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      {profile.photo ? (
        <div
          css={{
            border: '1px solid black',
            width: 200,
            height: 200,
            marginLeft: isMobile ? -150 : -300,
            marginTop: -300,
            zIndex: backgroundBoxes.length + 1,
          }}
        >
          <img src={profile.photo[0]?.url} alt={profile.first_name} css={{ width: 198 }} />
        </div>
      ) : null}
      <BoxProvider
        backgroundBoxes={backgroundBoxes}
        foregroundBoxes={foregroundBoxes}
        positioningConfig={{
          angle: 0,
          gap: 200,
          angleIncrement: 0.6,
          jitterMax: 60,
          spread: 0.25,
        }}
      />
      <EditProfileForm />
    </div>
  );
};

export default ProfilePage;
