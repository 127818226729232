import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import CallAPI from 'utils/CallAPI';
import useProfile from 'contexts/profileContext';
import { useLocation } from 'react-router-dom';

const EditPieceFormSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  price: Yup.number().required('Price is required').positive('Price must be positive'),
  width: Yup.number().required('Width is required').positive('Price must be positive'),
  height: Yup.number().required('Height is required').positive('Price must be positive'),
  medium: Yup.string().required('Medium is required'),
  images: Yup.mixed().required('At least one image is required'),
});

const EditPieceForm = ({ isOpen, setIsOpen }) => {
  const { genFetchPieces } = useProfile();
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any>([]);
  const location = useLocation();

  const clearQueryParam = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('uploadPiece');
    window.history.replaceState(null, '', currentUrl);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('uploadPiece')) {
      setIsOpen(true);
    }
  }, [location]);

  const handleClose = useUpdatingCallback(() => {
    if (setIsOpen) {
      setIsOpen(false);
      clearQueryParam();
    }
  });

  // const generateImagePreviews = (files) => {
  //   const previews = Array.from(files).map((file) => URL.createObjectURL(file as Blob));
  //   setImagePreviews(previews);
  // };

  const handleFileChange = (event, setFieldValue) => {
    if (!event?.currentTarget?.files) return;

    const newFiles = Array.from(event.currentTarget.files);
    const updatedFileList = [...fileList, ...newFiles];
    setFileList(updatedFileList);

    setFieldValue('images', updatedFileList);
    const newPreviews = newFiles.map((file) => URL.createObjectURL(file as Blob));
    setImagePreviews((oldPreviews) => [...oldPreviews, ...newPreviews]);
  };

  const clearImagePreview = (setFieldValue) => {
    setImagePreviews([]);
    setFileList([]);
    setFieldValue('images', []);
  };

  const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
    const files = values.images;
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 500 * 1024 * 1024; // 500MB

    for (let file of files) {
      if (!validTypes.includes(file.type)) {
        setFieldError('images', 'Invalid file type');
        setSubmitting(false);
        return;
      }
      if (file.size > maxSize) {
        setFieldError('images', 'File size exceeds limit');
        setSubmitting(false);
        return;
      }
    }

    try {
      // Call the API method for image upload
      await CallAPI.postCreatePiece({
        title: values.title,
        description: values.description,
        width: values.width,
        height: values.height,
        medium: values.medium,
        price: values.price,
        images: values.images,
      });
      await genFetchPieces();
      handleClose();
      // Handle success (e.g., show success message, close form)
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error('Error uploading images:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClose={handleClose} css={{ outline: 'none' }}>
      <div
        css={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          border: '1px solid black',
          background: 'white',
          padding: 24,
          outline: 'none',
        }}
      >
        <h3 css={{ marginTop: 0 }}>Upload a new piece</h3>
        <Formik
          initialValues={{
            title: '',
            description: '',
            price: '',
            width: '',
            height: '',
            medium: '',
            images: [],
          }}
          validationSchema={EditPieceFormSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form css={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <__FormFieldRow name='title' label='Title'>
                <Field type='text' name='title' placeholder='Title' css={{ width: '100%' }} />
              </__FormFieldRow>

              <__FormFieldRow name='description' label='Description'>
                <Field
                  type='text'
                  name='description'
                  placeholder='Description'
                  css={{ width: '100%' }}
                />
              </__FormFieldRow>

              <__FormFieldRow name='medium' label='Medium'>
                <Field type='text' name='medium' placeholder='Medium' css={{ width: '100%' }} />
              </__FormFieldRow>

              <div css={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                <div css={{ flex: 1 }}>
                  <__FormFieldRow name='width' label='Width' labelStyles={{ width: 78 }}>
                    <Field type='number' name='width' placeholder='Width' css={{ width: 100 }} />
                  </__FormFieldRow>
                </div>

                <div css={{ flex: 1 }}>
                  <__FormFieldRow
                    name='height'
                    label='Height'
                    labelStyles={{ width: 64, marginLeft: 10 }}
                  >
                    <Field
                      type='number'
                      name='height'
                      placeholder='Height'
                      css={{ width: 100, marginLeft: -10 }}
                    />
                  </__FormFieldRow>
                </div>
              </div>

              <__FormFieldRow name='price' label='Price'>
                <Field type='number' name='price' placeholder='Price' css={{ width: '100%' }} />
              </__FormFieldRow>

              <__FormFieldRow name='images' label='Images'>
                <input
                  id='images'
                  name='images'
                  type='file'
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  multiple
                  accept='image/jpeg, image/png, image/gif'
                  css={{ width: '100%' }}
                />
                <div
                  css={{
                    background: 'white',
                    height: 24,
                    width: 175,
                    position: 'absolute',
                    zIndex: 2000,
                    right: 0,
                  }}
                ></div>
              </__FormFieldRow>
              <ImageCarousel images={imagePreviews} />

              <div>
                {imagePreviews.length > 0 && (
                  <button type='button' onClick={() => clearImagePreview(setFieldValue)}>
                    Clear
                  </button>
                )}
                <button type='submit' disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const __FormFieldRow = ({ name, label, labelStyles = {}, children }) => (
  <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        position: 'relative',
      }}
    >
      <label htmlFor={name} css={{ fontSize: 12, width: 100, ...labelStyles }}>
        {label}:
      </label>
      {children}
    </div>
    <ErrorMessage name={name} component='div' css={{ color: 'red', fontSize: 12 }} />
  </div>
);

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (images.length === 0) return null;

  return (
    <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      {images.length > 1 && (
        <button type='button' onClick={handlePrev}>
          {'<'}
        </button>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <img
          src={images[currentIndex]}
          alt={`Preview ${currentIndex + 1}`}
          style={{ maxHeight: 200, maxWidth: 300, objectFit: 'contain', margin: '0 10px' }}
        />
        <div css={{ fontSize: 12 }}>{`${currentIndex + 1} / ${images.length}`}</div>
      </div>

      {images.length > 1 && (
        <button type='button' onClick={handleNext}>
          {'>'}
        </button>
      )}
    </div>
  );
};

export default EditPieceForm;
