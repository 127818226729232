import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { isMobile, browserName } from 'react-device-detect';
import { GoogleGIcon } from 'Icons';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Text from 'components/Text';
import AuthDS, { useAuth } from 'data/AuthDS';
import useUser from 'contexts/userContext';
import { useLocation } from 'react-router-dom';
import isProd from 'utils/isProd';
import SimpleButton from 'components/SimpleButton';

const SignUpModal = ({
  isOpen = null,
  setIsOpen,
}: {
  isOpen?: boolean | null;
  setIsOpen?: Function;
}) => {
  const { userId } = useUser();
  const isAuthModalOpen = useAuth({ transform: (x) => x?.isAuthModalOpen });
  const location = useLocation();

  const handleClose = useUpdatingCallback(() => {
    AuthDS.get().closeAuthModal();

    if (setIsOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (!location) return;

    const loginUrl = isProd() ? 'https://tngbl.art/login' : 'http://localhost:3000/login';
    const isLoginRoute = location.pathname === new URL(loginUrl).pathname;

    if (isLoginRoute) {
      AuthDS.get().openAuthModal();
    }
  }, [location]);

  useEffect(() => {
    if (userId) {
      AuthDS.get().closeAuthModal();
    }
  }, [userId]);

  return (
    <Modal
      open={isOpen !== null ? isOpen : isAuthModalOpen}
      onClose={handleClose}
      css={{ outline: 'none' }}
    >
      <div
        css={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? 'calc(100vw - 10px)' : 400,
          border: '1px solid black',
          background: 'white',
          padding: 24,
          outline: 'none',
        }}
      >
        {__isInAppBrowser() ? (
          <div>
            <p>
              It looks like you&apos;re using an in-app browser. For the best experience, please
              copy and open this link in your regular browser:
            </p>
            <__CopyLinkButton />
          </div>
        ) : (
          <>
            <__Header />
            <div
              css={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: 24,
              }}
            >
              <___ConnectAccountButton
                href='/auth/google/login'
                Icon={<GoogleGIcon />}
                text='Google'
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const __Header = () => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div
        css={{
          lineHeight: '24px',
          fontWeight: 600,
          fontSize: 16,
          color: 'black',
          marginBottom: 12,
          flex: 1,
          pointerEvents: 'none',
        }}
      >
        Sign-up or Log-in
      </div>
      <div
        css={{
          lineHeight: '24px',
          fontWeight: 500,
          fontSize: 16,
          color: 'black',
          marginBottom: 12,
          flex: 1,
          pointerEvents: 'none',
          opacity: 0.7,
        }}
      >
        Create an account or login to your existing account. You must use your RCA email.
      </div>
    </div>
  );
};

const ___ConnectAccountButton = ({
  href,
  Icon,
  text,
}: {
  href: string;
  Icon: any;
  text: string;
}) => {
  // const navigate = useNavigate();

  // const __handleClick = useUpdatingCallback((evt) => {
  //   let videoId = AuthDS.get().getRedirectVideoId() || window.location.pathname.slice(1);

  //   if (videoId) {
  //     let url = 'https://youtu.be/' + videoId;

  //     if (isValidYouTubeURL(url)) {
  //       window.sessionStorage.setItem('videoId', videoId);
  //     }
  //   }

  //   // navigate(`${process.env.REACT_APP_API_ENDPOINT}${href}`);
  // });

  return (
    <a
      // onClick={__handleClick}
      href={href}
      css={{
        // borderRadius: 8,
        color: 'black',
        border: '1px solid black',
        padding: 2,
        width: 230,
        height: 40,
        fontSize: 24,
        cursor: 'pointer',
        '&:hover, &:focus': {
          background: 'lightgray',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        outline: 'none !important',
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 12,
        minWidth: 250,
      }}
    >
      {Icon}
      <Text
        ellipsifyLines={1}
        extraCSS={{
          fontSize: 15,
          fontWeight: 600,
          lineHeight: '24px',
          margin: 0,
          padding: 0,
          marginLeft: 8,
          color: 'black',
          alignItems: 'center',
        }}
      >
        Continue with {text}
      </Text>
    </a>
  );
};

const __CopyLinkButton = () => {
  const [buttonText, setButtonText] = useState('Copy Link');
  const linkToCopy = isProd() ? 'https://tngbl.art/login' : 'http://localhost:3000/login'; // Replace with your link

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setButtonText('Copied!');
        setTimeout(() => {
          setButtonText('Copy Link');
        }, 3000); // Revert back after 3 seconds
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div css={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <SimpleButton text={buttonText} onClick={copyToClipboard} />
    </div>
  );
};

const __isInAppBrowser = () => {
  const userAgent = navigator.userAgent;
  // Detect Instagram
  if (userAgent.toLowerCase().includes('instagram')) {
    return true;
  }

  if (browserName === 'WebKit') return true;

  // Check for Safari or Chrome on iOS
  // @ts-ignore
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream;
  const isSafariOrChrome =
    isIOS && (navigator.userAgent.includes('Safari') || navigator.userAgent.includes('CriOS'));

  // Check for standalone mode
  const isInStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone;

  // Browsers in-app typically don't have 'Safari' or 'CriOS' (Chrome for iOS) in the user agent
  // and are not in standalone mode
  if (isIOS && !isSafariOrChrome && !isInStandaloneMode) {
    return true;
  }

  // Additional checks for Android or other platforms can be added here

  return false;
};

export default SignUpModal;
