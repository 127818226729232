import React from 'react';
import Modal from '@mui/material/Modal';
import { isMobile } from 'react-device-detect';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import ModalsDS, { useModals } from 'data/ModalsDS';
import SimpleButton from './SimpleButton';

const HowDoesItWorkModal = () => {
  const isOpen = useModals({ transform: (x) => x?.isHowItWorksModalOpen });

  const handleClose = useUpdatingCallback(() => {
    ModalsDS.get().closeHowItWorksModal();
  });

  return (
    <Modal open={isOpen} onClose={handleClose} css={{ outline: 'none' }}>
      <div
        css={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? 'calc(100vw - 10px)' : 400,
          border: '1px solid black',
          background: 'white',
          padding: 24,
          outline: 'none',
        }}
      >
        <__Header />
        <div
          css={{
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: 24,
          }}
        ></div>
      </div>
    </Modal>
  );
};

const __Header = () => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <SimpleButton
        text='X'
        onClick={() => ModalsDS.get().closeHowItWorksModal()}
        extraCSS={{
          position: 'absolute',
          right: 0,
          top: 0,
          textDecoration: 'none',
          color: 'black',
        }}
      />
      <div
        css={{
          lineHeight: '24px',
          fontWeight: 600,
          fontSize: 16,
          color: 'black',
          marginBottom: 12,
          flex: 1,
          pointerEvents: 'none',
        }}
      >
        How does it work?
      </div>
      <div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <ol>
          <li>You drop off one piece</li>
          <li>
            You pick up one piece (You can also just pick up something without dropping anything
            off)
          </li>
          <li>Each month you can choose to buy the piece, or to return it.</li>
          <li>Either way you can get a new one next month.</li>
        </ol>
      </div>
    </div>
  );
};

export default HowDoesItWorkModal;
