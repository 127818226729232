import ReactDOM from 'react-dom';

export default class DataSource {
  __listenersByCounterIDMap: { [id: number]: Function };
  __state;

  constructor(initialState?: object) {
    this.__listenersByCounterIDMap = {};
    this.__state = initialState || {};
  }

  getState() {
    return this.__state;
  }

  set(state): void {
    this.__state = state;
    this.dispatch();
  }

  patch(partialState): void {
    this.__state = { ...this.__state, ...partialState };
    this.dispatch();
  }

  subscribe(callBackFn: (state) => void) {
    const callBackFnID = idCounter;
    idCounter++;

    this.__listenersByCounterIDMap[callBackFnID] = callBackFn;

    callBackFn(this.getState());

    return () =>
      this.__listenersByCounterIDMap[callBackFnID] &&
      delete this.__listenersByCounterIDMap[callBackFnID];
  }

  dispatch() {
    const currentState = this.getState();
    ReactDOM.unstable_batchedUpdates(() => {
      Object.values(this.__listenersByCounterIDMap).forEach((callBackFn) =>
        callBackFn(currentState),
      );
    });
  }
}

let idCounter = 0;
