import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useAllPieces from 'hooks/useAllPieces';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Box from 'components/Box';
import useUser from 'contexts/userContext';
import CreateProfileForm from 'components/CreateProfileForm';
import BoxProvider from 'contexts/boxContext';
import SimpleButton from 'components/SimpleButton';
import LoginOrLogoutButton from 'components/LoginOrLogoutButton';
import useDidUserCreateProfile from 'hooks/useDidUserCreateProfile';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';
import UploadPieceButton from 'components/UploadPieceButton';
import ModalsDS from 'data/ModalsDS';
import HowDoesItWorkModal from 'components/HowDoesItWorkModal';
import { isMobile } from 'react-device-detect';

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pieces = useAllPieces();
  const { userId } = useUser();
  const { didUserCreateProfile } = useDidUserCreateProfile();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('uploadPiece') && didUserCreateProfile) {
      navigate(`/profile/${userId}?uploadPiece`);
    } else if (searchParams.has('uploadPiece') && !didUserCreateProfile) {
      localStorage.setItem('uploadPiece', 'true');
    }
  }, [location, didUserCreateProfile]);

  const handleClickProfile = useUpdatingCallback(() => {
    navigate(`/profile/${userId}`);
  });

  const handleClickHowDoesItWork = useUpdatingCallback(() => {
    ModalsDS.get().openHowItWorksModal();
  });

  const backgroundBoxes = pieces.map((piece) => (
    <Box key={piece.uid} width={200} height={200}>
      <img
        src={piece.images[0].url}
        alt={piece.title}
        css={{ width: 200, cursor: 'pointer' }}
        onClick={() => navigate(`/piece/${piece.uid}`)}
      />
    </Box>
  ));
  const foregroundBoxes = [
    <Box width={200} height={75} isForeground>
      <SimpleButton onClick={handleClickHowDoesItWork} text='How does it work?' />
    </Box>,
    <Box key='profile_box' height={60} width={100} isForeground>
      <SimpleButton onClick={handleClickProfile} text='Profile' />
    </Box>,
    <Box key='login_box' height={60} width={100} isForeground>
      <LoginOrLogoutButton isInLandingPage />
    </Box>,
    <Box key='upload_piece_box' height={60} width={150} isForeground>
      <UploadPieceButton />
    </Box>,
  ];

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        // width: '100%',
        width: '100vw',
        // height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      <HowDoesItWorkModal />
      <BoxProvider
        backgroundBoxes={isMobile ? [] : backgroundBoxes}
        foregroundBoxes={foregroundBoxes}
        positioningConfig={{
          angle: 0,
          gap: 200,
          angleIncrement: 0.6,
          jitterMax: 60,
          spread: 0.25,
        }}
      />
      <CreateProfileForm />
      {isMobile
        ? __shuffleArray(pieces).map((piece) => (
            <img
              key={piece.uid}
              src={piece.images[0].url}
              alt={piece.title}
              css={{
                // width: '100%',
                cursor: 'pointer',
                border: '1px solid black',
                boxSizing: 'border-box',
                height: 'auto',
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              onClick={() => navigate(`/piece/${piece.uid}`)}
            />
          ))
        : null}
    </div>
  );
};

const __shuffleArray = (array) => {
  // Creating a copy of the original array
  let arrayCopy = array.slice();

  for (let i = arrayCopy.length - 1; i > 0; i--) {
    // Generate a random index
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j in the copy
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }
  return arrayCopy;
};

export default HomePage;
