import { useEffect, useState } from 'react';
import CallAPI from 'utils/CallAPI';
import Profile from 'types/Profile';

const useProfileByID = ({ userId }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (userId) {
      __genGetProfileById({ setProfile, userId, setIsLoading, setIsError });
    }
  }, [userId]);

  return { profile, isLoading, isError };
};

const __genGetProfileById = async ({ setProfile, userId, setIsLoading, setIsError }) => {
  try {
    const res = await CallAPI.getProfileByID({ uid: userId });
    setProfile(res.data.result.user);
  } catch (err) {
    setIsError(true);
  } finally {
    setIsLoading(false);
  }
};
export default useProfileByID;
