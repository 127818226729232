import DataSource from 'data/DataSource';
import useDataSource from 'data/useDataSource';

class AuthDS extends DataSource {
  static __instance: any;
  static get() {
    if (!this.__instance) {
      this.__instance = new AuthDS();
    }
    return this.__instance;
  }

  constructor() {
    super({ userId: '', isAuthModalOpen: false, redirectVideoId: '' });
  }

  getUserId(): string {
    return this.getState()?.userId || '';
  }

  getIsAuthModalOpen(): string {
    return this.getState()?.isAuthModalOpen || '';
  }

  getRedirectVideoId(): string {
    return this.getState()?.redirectVideoId || '';
  }

  setUserId(userId: string) {
    this.patch({ userId });
  }

  setRedirectVideoId(videoId: string) {
    this.patch({ redirectVideoId: videoId });
  }

  openAuthModal() {
    this.patch({ isAuthModalOpen: true });
  }

  closeAuthModal() {
    this.patch({ isAuthModalOpen: false });
  }
}

export const useAuth = (params?: { dependencies?: any[]; transform?: Function }) => {
  return useDataSource(AuthDS.get(), params?.dependencies || [], params?.transform);
};

export const SESSION_EXPIRED_MESSAGE = 'Your session has expired, please login again';

export default AuthDS;
