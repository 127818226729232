import React from 'react';
import { ellipsis } from 'polished';
import colors from 'colors';

const Text = ({ textRef = null, children, ellipsifyLines, extraCSS }) => {
  let ellipsisProps = ellipsifyLines ? ellipsis('100%', ellipsifyLines) : {};

  if (ellipsifyLines === 1) {
    ellipsisProps = {
      ...ellipsisProps,
      '-webkit-line-clamp': '1',
      wordBreak: 'break-word',
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
    };
    delete ellipsisProps.whiteSpace;
  }
  if (ellipsifyLines === 2) {
    ellipsisProps = {
      ...ellipsisProps,
      // '-webkit-line-clamp': '1',
      wordBreak: 'break-word',
      overflowWrap: 'break-all',
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
    };
    delete ellipsisProps.whiteSpace;
  }

  const props = textRef ? { ref: textRef } : {};

  return (
    <div
      {...props}
      css={{
        ...ellipsisProps,
        color: colors.p_text,
        wordBreak: 'break-word',
        overflowWrap: 'break-all',
        ...extraCSS,
      }}
    >
      {children}
    </div>
  );
};

export default Text;
