import React from 'react';
import useUser from 'contexts/userContext';
import SignUpModal from 'components/SignUpModal';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import BasicButton from 'components/BasicButton';
import SimpleButton from 'components/SimpleButton';
import AuthDS from 'data/AuthDS';

const LoginOrLogoutButton = ({ isInLandingPage }: { isInLandingPage: boolean }) => {
  const { userId, genLogout } = useUser();

  const handleLogin = useUpdatingCallback(() => AuthDS.get().openAuthModal());
  const handleLogout = useUpdatingCallback(() => {
    // ReactGA.event({
    //   category: 'User',
    //   action: 'LoggedOut',
    // });
    genLogout();
  });

  if (userId) {
    return <SimpleButton onClick={handleLogout} text='Logout' />;
  }

  return (
    <>
      {isInLandingPage ? (
        <SimpleButton onClick={handleLogin} text='LOG IN / SIGN UP' />
      ) : (
        <BasicButton
          key='login'
          text='Login'
          onClick={handleLogin}
          extraCSS={{
            borderRadius: 6,
            marginLeft: 12,
            height: 44,
            backgroundColor: 'rgba(0,0,0,0.2)',
            backdropFilter: 'blur(10px)',
            color: 'rgba(0,0,0,0.5)',
            '&:hover': { color: 'rgba(0,0,0,0.8)' },
          }}
        />
      )}
      <SignUpModal />
    </>
  );
};

export default LoginOrLogoutButton;
