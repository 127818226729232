import React from 'react';
import { useBoxPosition } from 'contexts/boxContext';

const Box = ({
  children,
  canHover = true,
  isForeground = false,
  index = 0,
  width,
  height,
  extraCSS = {},
}) => {
  const { backgroundBoxPositions, foregroundBoxPositions, setHoverIndex, hoverIndex } =
    useBoxPosition();
  const positions = isForeground ? foregroundBoxPositions : backgroundBoxPositions;
  const pos = positions ? positions[index] : null;

  const handleMouseEnter = () => {
    if (!canHover) return;
    setHoverIndex({ index, type: isForeground ? 'foreground' : 'background' });
  };

  const handleMouseLeave = () => {
    if (!canHover) return;
    setHoverIndex({ index: null, type: null });
  };

  if (!pos) return null;

  const zIndex =
    hoverIndex.index === index && hoverIndex.type === (isForeground ? 'foreground' : 'background')
      ? 1001
      : pos.zIdx;

  return (
    <div
      key={`box_${index}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: `${pos.initialLeft}px`,
        top: `${pos.top}px`,
        backgroundColor: 'white',
        width,
        height,
        border: '1px solid black',
        boxSizing: 'border-box',
        overflow: 'hidden',
        zIndex: zIndex,
        transition: 'left 4s cubic-bezier(0.19, 1, 0.22, 1)', // ease
        ...extraCSS,
      }}
    >
      {children}
    </div>
  );
};

export default Box;
