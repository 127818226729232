import browserDetect from 'browser-detect';
import { isMobile } from 'react-device-detect';

// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
const maxHeightOnMobileSafariCSS =
  isMobile && browserDetect().name === 'safari'
    ? { minHeight: '-webkit-fill-available', maxHeight: '-webkit-fill-available' }
    : {};

export default maxHeightOnMobileSafariCSS;
