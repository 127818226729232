import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import Dimensions from 'utils/Dimensions';
import { isMobile } from 'react-device-detect';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';
import SignUpModal from 'components/SignUpModal';

const BasicPageWrapper = ({ children }) => {
  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        minHeight: '100vh',
        ...maxHeightOnMobileSafariCSS,
      }}
    >
      <__Header />
      <SignUpModal />
      {children}
    </div>
  );
};

const __Header = () => {
  const navigate = useNavigate();
  const handleLogoClick = useUpdatingCallback(() => {
    // setVideoURL('');
    // ChatMessagesDS.get().clear();
    // setIsLoadingVideo(true);
    navigate('/');
  });

  // const isInLandingPage = window.location.pathname === '/';

  // if (isMobile && !isInLandingPage) {
  //   return null;
  // }

  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        minHeight: Dimensions.HEADER_HEIGHT,
        maxHeight: Dimensions.HEADER_HEIGHT,
        paddingLeft: isMobile ? 5 : 24,
        paddingRight: isMobile ? 5 : 24,
        height: Dimensions.HEADER_HEIGHT,
        width: '100%',
        alignItems: 'center',
        // borderBottom: isInLandingPage ? '0' : '1px solid rgba(45, 45, 45, 0.1)',
        // borderBottom: '1px solid black',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          maxWidth: isMobile ? '100vw' : Dimensions.MAX_BODY_WIDTH,
          minWidth: isMobile ? '' : Dimensions.MIN_BODY_WIDTH,
          marginLeft: 0,
        }}
      >
        <img
          onClick={handleLogoClick}
          src={process.env.PUBLIC_URL + '/tngbl_logo.svg'}
          css={{ width: 145, position: 'relative', cursor: 'pointer', zIndex: 1 }}
          alt='logo'
        />
      </div>
    </div>
  );
};

export default BasicPageWrapper;
