import { useEffect, useState } from 'react';
import CallAPI from 'utils/CallAPI';
import Piece from 'types/Piece';

const useAllPieces = () => {
  const [pieces, setPieces] = useState<Piece[]>([]);

  useEffect(() => {
    __genGetAllPieces({ setPieces });
  }, []);

  return pieces;
};

const __genGetAllPieces = async ({ setPieces }) => {
  const res = await CallAPI.getAllPieces();

  setPieces(res.data.result.pieces);
};

export default useAllPieces;
