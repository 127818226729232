import DataSource from 'data/DataSource';
import useDataSource from 'data/useDataSource';

class ModalsDS extends DataSource {
  static __instance: any;
  static get() {
    if (!this.__instance) {
      this.__instance = new ModalsDS();
    }
    return this.__instance;
  }

  constructor() {
    super({
      isProfileModalOpen: false,
      isUploadPieceModalOpen: false,
      isHowItWorksModalOpen: false,
    });
  }

  getIsProfileModalOpen(): boolean {
    return this.getState()?.isProfileModalOpen || false;
  }

  openProfileModal() {
    this.patch({ isProfileModalOpen: true });
  }

  closeProfileModal() {
    this.patch({ isProfileModalOpen: false });
  }

  getIsUploadPieceModalOpen(): boolean {
    return this.getState()?.isProfileModalOpen || false;
  }

  openUploadPieceModal() {
    this.patch({ isUploadPieceModalOpen: true });
  }

  closeUploadPieceModal() {
    this.patch({ isUploadPieceModalOpen: false });
  }

  getIsHowItWorksModalOpen(): boolean {
    return this.getState()?.isProfileModalOpen || false;
  }

  openHowItWorksModal() {
    this.patch({ isHowItWorksModalOpen: true });
  }

  closeHowItWorksModal() {
    this.patch({ isHowItWorksModalOpen: false });
  }
}

export const useModals = (params?: { dependencies?: any[]; transform?: Function }) => {
  return useDataSource(ModalsDS.get(), params?.dependencies || [], params?.transform);
};

export default ModalsDS;
