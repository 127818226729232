import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

class CallAPI {
  static async getAllPiecesByUserID({ uid }) {
    return await __genCallAPI({ apiName: 'getAllPiecesByUserID', params: { uid } });
  }

  static async getPieceByID({ uid }) {
    return await __genCallAPI({ apiName: 'getPieceByID', params: { uid } });
  }

  static async getProfileByID({ uid }) {
    return await __genCallAPI({ apiName: 'getProfileByID', params: { uid } });
  }

  static async getDidUserCreateProfile() {
    return await __genCallAPI({ apiName: 'getDidUserCreateProfile' });
  }

  static async postCreatePiece({ title, description, width, height, medium, price, images }) {
    const accessToken = window.localStorage.getItem('accessToken') || '';
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('width', width.toString());
    formData.append('height', height.toString());
    formData.append('medium', medium);
    formData.append('price', price.toString());

    Array.from(images).forEach((image: Blob) => {
      formData.append('images', image);
    });

    return await instance.post('/api/postCreatePiece', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async postUpdatePiece({ title, description, price, images }) {
    return await __genCallAPI({
      apiName: 'postUpdatePiece',
      params: { title, description, price, images },
    });
  }

  // static async postCreateUser({
  //   firstName,
  //   lastName,
  //   email,
  //   phone,
  //   program,
  //   instagram,
  //   photo,
  // }) {
  //   return await __genCallAPI({
  //     apiName: 'postCreateUser',
  //     params: { firstName, lastName, email, phone, program, instagram, photo },
  //   });
  // }

  static async postUpdateProfile({
    username,
    firstName,
    lastName,
    bio,
    phone,
    website,
    program,
    instagram,
    photo,
    is_collector,
    is_creator,
  }) {
    const accessToken = window.localStorage.getItem('accessToken') || '';
    const formData = new FormData();
    formData.append('username', username);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('bio', bio);
    formData.append('phone', phone);
    formData.append('website', website);
    formData.append('program', program);
    formData.append('instagram', instagram);
    formData.append('photo', photo);
    formData.append('is_collector', is_collector);
    formData.append('is_creator', is_creator);

    return await instance.post('/api/postUpdateProfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async getAllPieces() {
    return await __genCallAPI({ apiName: 'getAllPieces' });
  }

  /*
    Auth API
  */
  static async getCurrentSession() {
    return await __genCallAPI({ apiRoute: 'auth', apiName: 'getCurrentSession' });
  }

  static async logout(params: { accessToken: string }) {
    return await __genCallAPI({ apiRoute: 'auth', apiName: 'logout', params });
  }
}

// async function __genHandleChatAPIError({ apiName, params }: { apiName: string; params: any }) {
//   try {
//     return await __genCallAPI({ apiName, params });
//   } catch (error) {
//     // authorization error or session expired
//     if (error.response.status === 403) {
//       AuthDS.get().setUserId('');
//     } else {
//       ChatMessagesDS.get().addMessage({
//         user: 'CHATGPT',
//         id: uuidv4(),
//         text: `An error occurred, our team has been notified and will investigate. Please try again. If the issue persists, refresh your tab. `,
//       });
//       // Sentry.withScope((scope) => {
//       //   const data = {
//       //     apiName,
//       //     params,
//       //   };
//       //   scope.setExtra('data', data);
//       //   Sentry.captureException(error);
//       // });
//     }
//   }
// }

async function __genCallAPI({
  apiRoute = 'api',
  apiName,
  params = {},
  config = {},
}: {
  apiRoute?: string;
  apiName: string;
  params?: any;
  config?: any;
}) {
  let authParams = {};
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken) {
    authParams = { accessToken };
  } else {
    const anonVisitorId = window.localStorage.getItem('anonVisitorId');
    authParams = { anonVisitorId };
  }

  return await instance.post(`/${apiRoute}/${apiName}`, { ...params, ...authParams }, config);
}

export default CallAPI;
