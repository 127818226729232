import React from 'react';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import UploadPieceForm from 'components/UploadPieceForm';
import useUser from 'contexts/userContext';
import AuthDS from 'data/AuthDS';
import ModalsDS from 'data/ModalsDS';

const UploadPieceButton = () => {
  const { userId } = useUser();

  const onClick = useUpdatingCallback(() => {
    if (!userId) {
      AuthDS.get().openAuthModal();
      return;
    }
    ModalsDS.get().openUploadPieceModal();
  });

  return (
    <>
      <div
        onClick={onClick}
        css={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifContent: 'center',
          cursor: 'pointer',
          color: '#0000EE',
          textDecoration: 'underline',
          // marginRight: 12,
        }}
      >
        Upload Art
      </div>
      <UploadPieceForm />
    </>
  );
};

export default UploadPieceButton;
