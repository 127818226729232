import { useEffect, useState } from 'react';
import CallAPI from 'utils/CallAPI';
import Piece from 'types/Piece';

const usePiece = ({ pieceId }) => {
  const [piece, setPiece] = useState<Piece | null>(null);

  useEffect(() => {
    if (pieceId) {
      __genGetPieceByID({ setPiece, pieceId });
    }
  }, [pieceId]);

  return piece;
};

const __genGetPieceByID = async ({ setPiece, pieceId }) => {
  try {
    const res = await CallAPI.getPieceByID({ uid: pieceId });
    setPiece(res.data.result.piece);
  } catch (error) {
    console.log(error);
    setPiece(null);
  }
};

export default usePiece;
