import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CallAPI from 'utils/CallAPI';
import Profile from 'types/Profile';
import Piece from 'types/Piece';

type ProfileContextType = {
  profile: Profile | null;
  profilePieces: Piece[];
  genFetchPieces: Function;
  isError: boolean;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: null,
  profilePieces: [],
  genFetchPieces: () => {},
  isError: false,
});

export const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [pieces, setPieces] = useState<Piece[]>([]);
  const [userId, setUserId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!location) return;

    const userId = location.pathname.slice(1).replace('profile/', '');
    setUserId(userId);
  }, [location]);

  useEffect(() => {
    if (userId) {
      __genGetProfileById({ setProfile, userId, setIsLoading, setIsError });
      __genGetAllPieces({ setPieces, userId });
    }
  }, [userId]);

  async function genFetchPieces(): Promise<void> {
    await __genGetAllPieces({ setPieces, userId });
  }

  const memoedValues = useMemo(
    () => ({ profile, profilePieces: pieces, genFetchPieces, isError }),
    [profile, pieces, genFetchPieces, isError],
  );

  return (
    <ProfileContext.Provider value={memoedValues}>{!isLoading && children}</ProfileContext.Provider>
  );
};

export default function useProfile(): ProfileContextType {
  return useContext(ProfileContext);
}

const __genGetProfileById = async ({ setProfile, userId, setIsLoading, setIsError }) => {
  try {
    const res = await CallAPI.getProfileByID({ uid: userId });
    setProfile(res.data.result.user);
  } catch (err) {
    setIsError(true);
  } finally {
    setIsLoading(false);
  }
};

const __genGetAllPieces = async ({ setPieces, userId }) => {
  const res = await CallAPI.getAllPiecesByUserID({ uid: userId });
  setPieces(res.data.result.pieces);
};
