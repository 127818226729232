import useUpdatingCallback from 'utils/useUpdatingCallback';

const BasicButton = ({
  onClick,
  extraCSS,
  text,
  disabled = false,
  Icon,
}: {
  onClick: ({ text, event }: { text?: string; event: React.MouseEvent }) => void | Promise<void>;
  extraCSS?: any;
  text?: string;
  disabled?: boolean;
  Icon?: any;
}) => {
  const _onClick = useUpdatingCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onClick({ text, event });
    }
  });
  return (
    <div
      onClick={_onClick}
      css={{
        opacity: disabled ? 0.5 : 1,
        borderRadius: 8,
        padding: 6,
        backgroundColor: 'grey',
        whiteSpace: 'nowrap',
        color: 'white',
        paddingLeft: 12,
        paddingRight: 12,
        minHeight: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        cursor: disabled ? 'default' : 'pointer',
        flexDirection: 'row',
        justifyContent: 'center',
        ...extraCSS,
      }}
    >
      {Icon && (
        <div
          css={{
            marginRight: text ? 4 : 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon />
        </div>
      )}
      {text}
    </div>
  );
};

export default BasicButton;
