import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from 'contexts/userContext';
import usePiece from 'hooks/usePiece';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import useProfileByID from 'hooks/useProfileByID';
import SimpleButton from 'components/SimpleButton';
import EditPieceForm from 'components/EditPieceForm';
import { isMobile } from 'react-device-detect';

const PiecePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useUser();
  const [pieceId, setPieceId] = useState<string | null>(null);
  const piece = usePiece({ pieceId });
  const { profile } = useProfileByID({ userId: piece?.artist });
  const userIsArtist = profile?.uid === userId;
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!location) return;

    const pieceId = location.pathname.slice(1).replace('piece/', '');
    setPieceId(pieceId);
  }, [location, userId]);

  const handleClickX = useUpdatingCallback(() => {
    navigate(-1);
  });

  const handleClickProfile = useUpdatingCallback(() => {
    navigate(`/profile/${profile?.uid}`);
  });

  const handleClickEdit = useUpdatingCallback(() => {
    setIsEditing(true);
  });

  if (!piece || !profile) return null;

  const name = profile?.username || `${profile.first_name} ${profile.last_name}`;
  const dimensions = `${piece.width}x${piece.height}in`;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        // height: `calc(100vh - ${Dimensions.HEADER_HEIGHT}px)`,
        justifyContent: 'center',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: -8,
          left: isMobile ? 12 : 48,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: 'auto',
          gap: 8,
        }}
      >
        <__Box extraCSS={{ position: 'relative', alignItems: 'flex-start', gap: 8 }}>
          <div css={{ fontSize: 36 }}>&quot;{piece.title}&quot;</div>
          <SimpleButton onClick={handleClickProfile} text={name} />
          <div css={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <div css={{ fontSize: 14 }}>${piece.price}</div>
            <div css={{ fontSize: 14 }}>{dimensions}</div>
          </div>
          <div css={{ fontSize: 14 }}>{piece.medium}</div>
          <div css={{ fontSize: 14 }}>{piece.description}</div>
        </__Box>
        {userIsArtist ? (
          <__Box extraCSS={{ position: 'relative', height: 48, minHeight: 48, overflow: 'hidden' }}>
            <SimpleButton onClick={handleClickEdit} text='Edit' />
          </__Box>
        ) : null}
      </div>
      <__Box
        extraCSS={{
          position: 'absolute',
          top: -8,
          right: isMobile ? 12 : 72,
          padding: 0,
          width: 36,
          height: 36,
          minHeight: 36,
          minWidth: 36,
          zIndex: 300,
          overflow: 'hidden',
        }}
      >
        <div
          onClick={handleClickX}
          css={{
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 24,
            cursor: 'pointer',
            color: '#0000EE',
          }}
        >
          X
        </div>
      </__Box>

      {piece.images.map((image, index) => (
        <img
          key={index}
          src={image.url}
          alt={piece.title}
          css={{
            // flex: 1,
            width: '90vw',
            height: 'auto',
            maxWidth: '100%',
            '&:hover': { zIndex: 200 },
            // position: 'relative',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      ))}

      <EditPieceForm isOpen={isEditing} setIsOpen={setIsEditing} />
    </div>
  );
};

const __Box = ({ children, extraCSS = {} }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        // left: `${pos.initialLeft}px`,
        // top: `${pos.top}px`,
        backgroundColor: 'white',
        width: 'auto',
        maxWidth: 600,
        height: 'auto',
        minHeight: 100,
        maxHeight: 200,
        border: '1px solid black',
        boxSizing: 'border-box',
        overflow: 'scroll',
        zIndex: 100,
        padding: 24,
        ...extraCSS,
      }}
    >
      {children}
    </div>
  );
};

export default PiecePage;
