import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import CssBaseline from '@mui/material/CssBaseline';
import LandingPage from 'pages/LandingPage';
import BasicPageWrapper from 'components/BasicPageWrapper';
import useUser, { UserProvider } from 'contexts/userContext';
import colors from 'colors';

// adds support for the css-in-js css prop
import type {} from 'styled-components/cssprop';
import HomePage from 'pages/HomePage';
import PiecePage from 'pages/PiecePage';
import { ProfileProvider } from 'contexts/profileContext';
import ProfilePage from 'pages/ProfilePage';
import PrivacyPage from 'pages/PrivacyPage';

const customTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  // @ts-ignore
  shadows: shadows.map(() => 'none'),
  palette: {
    mode: 'light',
    primary: {
      main: colors.p_text,
    },
    secondary: {
      main: '#FF621B',
    },
    info: {
      main: '#252525',
    },
    background: {
      default: colors.p_background,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    button: {
      fontFamily: 'Inter, sans-serif',
      textTransform: 'none',
    },
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <UserProvider>
          <_Router />
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
};

const _Router = () => {
  const { userId } = useUser();
  return (
    <Routes>
      <Route path='/privacy' element={<PrivacyPage />} />
      <Route
        path='/tos'
        element={<HtmlComponent htmlFile={process.env.PUBLIC_URL + '/tos.html'} />}
      />
      <Route
        path='/profile/:userId'
        element={
          <BasicPageWrapper>
            <ProfileProvider>
              <ProfilePage />
            </ProfileProvider>
          </BasicPageWrapper>
        }
      />
      <Route
        path='/piece/:pieceId'
        element={
          <BasicPageWrapper>
            <PiecePage />
          </BasicPageWrapper>
        }
      />
      {userId ? (
        <Route
          path='/*'
          element={
            <BasicPageWrapper>
              <HomePage />
            </BasicPageWrapper>
          }
        />
      ) : (
        <Route path='/*' element={<LandingPage />} />
      )}
    </Routes>
  );
};

const HtmlComponent = ({ htmlFile }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(htmlFile)
      .then((response) => response.text())
      .then((html) => setHtmlContent(html))
      .catch((error) => console.error('Error loading the HTML file:', error));
  }, [htmlFile]);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default App;
