const MAX_VIDEO_WIDTH = 1280;
const MAX_CHAT_WIDTH = 800;
const MIN_VIDEO_WIDTH = 400;
const MIN_CHAT_WIDTH = 400;

const Dimensions = {
  MAX_VIDEO_WIDTH: MAX_VIDEO_WIDTH,
  MAX_CHAT_WIDTH: MAX_CHAT_WIDTH,
  MIN_VIDEO_WIDTH: MIN_VIDEO_WIDTH,
  MIN_CHAT_WIDTH: MIN_CHAT_WIDTH,
  MAX_BODY_WIDTH: MAX_VIDEO_WIDTH + MAX_CHAT_WIDTH + 24,
  MIN_BODY_WIDTH: MIN_VIDEO_WIDTH + MIN_CHAT_WIDTH + 24,
  HEADER_HEIGHT: 60,
  // https://samuelkraft.com/blog/safari-15-bottom-tab-bars-web
  // Hack: env(safe-area-inset-bottom) seems to not be working
  SAFARI_MOBILE_TAB_HEIGHT: 80,
};

export default Dimensions;
