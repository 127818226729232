const colors = {
  const_black: '#000000',
  const_p_brand: '#acc850',
  const_near_black: '#373A3F',
  const_white: '#FFFFFF',
  const_blue: '#219EF9',
  const_red: '#FF6969',
  const_green: '#6DDE79',
  const_light_grey: '#D5D5D5',
  const_grey: '#BABABA',
  const_medium_grey: '#B8B8B8',
  const_dark_grey: '#666563',
  const_loom_black: 'rgb(5,5,5)',
  const_dark_purple: 'rgba(102, 61, 255, 1)',
  const_purple: 'rgba(170, 0, 255, 0.7)',
  const_pink: 'rgba(204, 68, 153, 0.7)',
  const_light_blue: '#83eaf1',
  const_charcoal: '#575757',
  const_bright_pink: '#EA54DB',

  const_curation_start: '#aa00ff',
  const_curation_end: '#f441a5',
  const_curation_background: '#33236e',

  const_consumption_start: 'rgb(153, 255, 0)',
  const_consumption_end: '#ffeb3b',
  const_consumption_background: '#505e20',

  p_brand: '#acc850', // green-yellow color
  p_background: '#fff',
  p_text: '#F7F8F9',
  s_text: '#939597',
  t_text: '#CBCCCE',
  p_card: '#27282B',
  p_border: '#9D9D9D',
  neutral_100: '#151617', // navigation bar
  neutral_90: '#3f4146',
  neutral_80: '#373A3F', // button background
  neutral_60: '#474B52', // tags
  neutral_40: '#8C8C8C', // levels
  white_to_black: '#000000',
  black_to_white: '#FFFFFF',
};

export default colors;
