import { useCallback, useRef } from 'react';

// A ref that updates its value on every rerender
function useUpdatingRef<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export default function useUpdatingCallback<T>(fn: (...args: any) => T) {
  const theFn = useUpdatingRef(fn);
  const wrappedFn = useCallback((...args) => {
    return theFn.current(...args);
  }, []);
  return wrappedFn;
}
