import equal from 'fast-deep-equal/es6/react';
import { useEffect, useState } from 'react';
import DataSource from 'data/DataSource';

const useDataSource = (
  ds: DataSource,
  dependencies: any[] = [],
  transform: Function = (x) => x,
) => {
  const [state, setState] = useState(transform(ds.getState()));
  useEffect(() => {
    const closeFn = ds.subscribe((_state) => {
      setState((prevValue) => {
        const newTransformedValue = transform(_state);
        if (equal(prevValue, newTransformedValue)) {
          return prevValue;
        }
        return newTransformedValue;
      });
    });

    return () => {
      closeFn();
    };
  }, dependencies);

  return state;
};

export default useDataSource;
